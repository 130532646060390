(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("sbInternalMsgBus"));
	else if(typeof define === 'function' && define.amd)
		define("sbBonusLib", ["sbInternalMsgBus"], factory);
	else if(typeof exports === 'object')
		exports["sbBonusLib"] = factory(require("sbInternalMsgBus"));
	else
		root["sbBonusLib"] = factory(root["sbInternalMsgBus"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__858__) {
return 